
    var riot = require('riot')
     require('riot-kit/lib/form');require('riot-kit/lib/auth/forgot-password');require('riot-kit/lib/auth/signup');require('riot-kit/lib/auth/change-password');require('riot-kit/lib/i18n');
riot.tag2('signin', '<div if="{error}" class="alert alert-danger"> <i18n content="{error}"></i18n> </div> <form width1="10" width2="2" class="form-horizontal"> <forminput name="username" label="Email" onupdate="{handleUpdate}" value="{user.username}" width1="2" width2="10"></forminput> <forminput type="password" name="password" label="Password" onupdate="{handleUpdate}" value="{user.password}" width1="2" width2="10"></forminput> <formsubmit show="{!opts.hidebutton}" onclick="{signin}" type="submit" width1="10" width2="2">Sign In</formsubmit> </form> <div class="signin-controls"> <p class="pull-right"><a if="{!opts.hideforgotpassword}" onclick="{showForgotPassword}"> <i18n>Forgotten Password?</i18n></a></p> </div> <div class="clearfix"></div> <forgot-password-modal username="{user.username}" custom-message="{opts.forgotPasswordCustomMessage}"></forgot-password-modal> <signup-modal username="{user.username}"></signup-modal>', '.signin-controls { clear: both; } .signin-controls a { cursor: pointer; }', '', function(opts) {
var loginApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

loginApi = new resource(opts.src || '/duocms/api/login', true);

this.on('mount', function() {
  return this.root.trigger = this.trigger;
});

this.on('reset', (function(_this) {
  return function() {
    return _this.reset();
  };
})(this));

this.on('signin', (function(_this) {
  return function() {
    return _this.signin();
  };
})(this));

this.user = {
  username: '',
  password: ''
};

this.reset = (function(_this) {
  return function() {
    _this.user.username = '';
    _this.user.password = '';
    return _this.update();
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(input) {
    return _this.user[input.name] = input.value;
  };
})(this);

this.signin = (function(_this) {
  return function() {
    _this.error = null;
    console.log("signing in", opts);
    return loginApi.save(_this.user, function(user, error) {
      if (error != null) {
        _this.error = error.message;
        return _this.update();
      } else {
        if ((opts.onsignin != null) && typeof opts.onsignin === "function") {
          _this.opts.onsignin(user);
        }
        if (opts.redirect != null) {
          window.location.href = opts.redirect;
        }
        if (opts.reload != null) {
          window.location.reload(true);
        }
        return null;
      }
    });
  };
})(this);

this.showForgotPassword = (function(_this) {
  return function() {
    return _this.tags['forgot-password-modal'].trigger('show');
  };
})(this);

this.showSignup = (function(_this) {
  return function() {
    return _this.tags['signup-modal'].trigger('show');
  };
})(this);
});
riot.tag2('signin-modal', '<yield></yield> <modal name="signinmodal" onclose="{close}" title="Sign In" savetext="Sign In" onsave="{handlesignin}" esckey="{true}"> <signin onsignin="{parent.signedin}" hidebutton="{true}" hidesignup="{true}" hideforgotpassword="{parent.opts.hideforgotpassword}" forgot-password-custom-message="{parent.opts.forgotPasswordCustomMessage}" redirect="{parent.opts.onLoginRedirect}" reload="{parent.opts.reload}"></signin> </modal> <modal name="signoutmodal" onclose="{close}" title="Account" savetext="Logout" onsave="{logout}" esckey="{true}"> <h4 riot-tag="i18n">You are already signed in.</h4><a href="{parent.securelink}" class="signedin-link btn btn-primary btn-arrow-white">Access Secure Area</a> </modal>', 'signin-modal { cursor: pointer; } .signedin-link { position: absolute; bottom: -50px; text-decoration: none; } @media (max-width: 767px) { .signedin-link { position: relative; bottom: auto; } }', 'onclick="{show}"', function(opts) {
var loginApi, logoutApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

loginApi = new resource(opts.src || '/duocms/api/login', true);

logoutApi = new resource(opts.src || '/duocms/api/logout', true);

this.securelink = opts.onLoginRedirect || "/";

this.on('mount', function() {
  this.root.trigger = this.trigger;
  this.signinmodal = this.tags.signinmodal;
  this.signoutmodal = this.tags.signoutmodal;
  this.signin = this.signinmodal.tags.signin;
  return this.update();
});

this.on('show', (function(_this) {
  return function() {
    return _this.show();
  };
})(this));

this.show = (function(_this) {
  return function() {
    document.addEventListener('keydown', _this.keydown);
    return loginApi.get(function(data, err) {
      if (err == null) {
        return _this.signoutmodal.show();
      } else {
        return _this.signinmodal.show();
      }
    });
  };
})(this);

this.keydown = (function(_this) {
  return function(e) {
    if (e.which === 13) {
      return _this.handlesignin();
    }
  };
})(this);

this.logout = (function(_this) {
  return function() {
    console.log('logout');
    return logoutApi.get(function(data, err) {
      if (err != null) {
        return console.log('error', err);
      } else {
        if (opts.onLogoutRedirect != null) {
          window.location.href = opts.onLogoutRedirect;
        }
        if ((opts.reload != null) & (opts.onLogoutRedirect == null)) {
          window.location.reload(true);
        }
        return _this.signoutmodal.close();
      }
    });
  };
})(this);

this.close = (function(_this) {
  return function() {
    document.removeEventListener('keydown', _this.keydown);
    return _this.signin.trigger('reset');
  };
})(this);

this.signedin = (function(_this) {
  return function(user) {
    _this.signinmodal.close();
    if ((opts.onsignin != null) && typeof opts.onsignin === "function") {
      return opts.onsignin(user);
    }
  };
})(this);

this.handlesignin = (function(_this) {
  return function() {
    return _this.signin.trigger('signin');
  };
})(this);
});
    
  