
    var riot = require('riot')
    
riot.tag2('require', '', '', 'dateformat', function(opts) {
});
riot.tag2('require', '', '', 'masonry-layout', function(opts) {
});

riot.tag2('twitter-script', '', '', '', function(opts) {
    !function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (!d.getElementById(id)){
        js = d.createElement(s);
        js.id = id;
        js.src = "https://platform.twitter.com/widgets.js";
        fjs.parentNode.insertBefore(js,fjs);
      }
    }(document, 'script', 'twitter-wjs');

});
riot.tag2('twitter', '<twitter-script></twitter-script><a href="{opts.url}" data-chrome="{opts.chrome}" data-widget-id="{opts.widgetid}" height="{opts.height}" class="twitter-timeline">Tweets by {opts.handle}</a>', '', '', function(opts) {
});
riot.tag2('twitter-list', '<virtual each="{tweet,i in tweets}"><yield></yield></virtual>', '', '', function(opts) {
var resource;

resource = require('riot-kit/utils/apiResource.coffee');

this.on('mount', function() {
  var params;
  params = opts.count ? '?count=' + opts.count : '';
  if (opts.users) {
    params = params + "&users=" + opts.users;
  }
  this.twitterApi = new resource((opts.src || '/duocms/api/tweets') + params);
  return this.twitterApi.get((function(_this) {
    return function(data) {
      return _this.onload(data);
    };
  })(this));
});

this.onload = (function(_this) {
  return function(data) {
    _this.tweets = data.map(function(tweet) {
      var ref;
      this.randimgs = ["/images/new_photography/tas_7179-flipped.pagespeed.1.jpg", "/images/new_photography/tas_6965lr.pagespeed.1.jpg", "/images/new_photography/tas_6992.pagespeed.1.jpg", "/images/new_photography/tas_7148-flipped.pagespeed.1.jpg", "/images/new_photography/tas_6984.pagespeed.1.jpg", "/images/new_photography/tas_7023.pagespeed.1.jpg", "/images/new_photography/tas_7138.pagespeed.1.jpg", "/images/new_photography/tas_7076.pagespeed.1.jpg", "/images/new_photography/tas_7095.pagespeed.1.jpg"];
      tweet.randomimage = this.randimgs[Math.floor(Math.random() * this.randimgs.length)];
      if ((ref = tweet.urls) != null) {
        ref.map(function(url) {
          return tweet.text = tweet.text.replace(url.url, '<a href="' + url.url + '" target="_blank">' + url.url + '</a>');
        });
      }
      tweet.created_at = new Date(tweet.created_at);
      return tweet;
    });
    if (opts.loaded) {
      opts.loaded(_this.tweets);
    }
    return _this.update();
  };
})(this);
});
riot.tag2('twitter-ctas', '<div riot-tag="twitter-list" riot-src="{opts.src}" users="{opts.users}" count="{opts.count}" loaded="{loaded}" class="dynamicList dl_twitternews twitter-ctas-inner row"> <div class="col-xs-12 col-sm-4"> <div class="item"> <div class="imgwrap"> <div class="ratiobox"></div> <div if="{tweet.media}" riot-style="background-image:url({tweet.media[0].media_url})" class="list-image"></div> <div if="{!tweet.media}" riot-style="background-image:url({tweet.randomimage})" class="list-image"></div><i class="icon et-folder"></i> </div> <div class="list-content"> <h4><a href="//twitter.com/{tweet.screen_name}">@{tweet.screen_name}</a><br><small> <dateformat date="{tweet.created_at}" format="dd MMM yyyy"> </dateformat></small></h4> <p riot-tag="raw" content="{tweet.text}" class="clamp"></p><a href="//twitter.com/{tweet.screen_name}" class="arrowlink">Read more here </a> </div> </div> </div> </div>', '', '', function(opts) {
});
riot.tag2('twitter-feed', '<div if="{opts.carousel}" class="carousel slide"> <ol if="{opts.showDots}" class="carousel-indicators"> <li each="{tweet,i in tweets}" onclick="{parent.goto}" class="dot {active:i==parent.active}"></li> </ol> <div riot-tag="twitter-list" riot-src="{opts.src}" count="{opts.count}" loaded="{loaded}" users="{opts.users}" class="carousel-inner"> <div class="item {parent.parent.slideclass[i]}"><i class="fa fa-twitter"></i> <p riot-tag="raw" content="{tweet.text}"></p> <p class="tweetdate">Posted on <dateformat date="{tweet.created_at}" format="dd MMMM yyyy"> </dateformat> </p> </div> </div> </div> <div if="{!opts.carousel}" class="twitter-outer"> <div riot-tag="twitter-list" riot-src="{opts.src}" users="{opts.users}" count="{opts.count}" loaded="{loaded}" class="twitter-inner twitter-carousel owl-carousel"> <div class="item {parent.parent.slideclass[i]}"> <div class="wrapper"> <p class="twitter-profile-image"><a href="//twitter.com/{tweet.screen_name}" target="_blank">@{tweet.screen_name}</a></p> <div class="indented"> <p class="tweetdate"><strong> <dateformat date="{tweet.created_at}" format="dd MMMM yyyy"> </dateformat></strong></p> <p riot-tag="raw" content="{tweet.text}" class="tweet-text"></p> <p if="{tweet.media}"><a href="{tweet.media[0].url}" target="_blank"><img riot-src="{tweet.media[0].media_url}" style="max-width:100%"></a></p> </div> </div> <div class="tweet-footer"><a href="//twitter.com/{tweet.screen_name}" target="_blank"><i class="fa fa-angle-right"></i> Follow us</a> <div class="pull-right"><a href="//twitter.com/intent/tweet?in_reply_to={tweet.id}" target="_blank"><i class="fa fa-reply"></i></a><a href="//twitter.com/intent/retweet?tweet_id={tweet.id}" target="_blank"><i class="fa fa-retweet"></i></a><a href=""><i class="fa fa-star"></i></a></div> </div> </div> </div> </div>', '', '', function(opts) {
this.interval = null;

this.animating = false;

this.slideclass = ['active'];

this.loaded = (function(_this) {
  return function(tweets) {
    _this.tweets = tweets;
    _this.active = 0;
    _this.total = _this.tweets.length;
    if (_this.opts.autoplay != null) {
      _this.interval = setInterval(function() {
        return _this.next();
      }, _this.opts.autoplay);
    }
    return _this.update();
  };
})(this);

this.next = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active++;
    if (_this.active >= _this.total) {
      _this.active = 0;
    }
    return _this.slidenext();
  };
})(this);

this.slidenext = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active left';
    _this.slideclass[_this.active] = 'next';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.active] = 'next left';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.active] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.update();
      return _this.animating = false;
    }, 700);
  };
})(this);

this.prev = (function(_this) {
  return function() {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active--;
    if (_this.active < 0) {
      _this.active = _this.total - 1;
    }
    return _this.slideprev();
  };
})(this);

this.slideprev = (function(_this) {
  return function() {
    _this.animating = true;
    _this.slideclass[_this.previndex] = 'active right';
    _this.slideclass[_this.active] = 'prev';
    _this.update();
    setTimeout(function() {
      _this.slideclass[_this.active] = 'prev right';
      return _this.update();
    }, 20);
    return setTimeout(function() {
      _this.slideclass[_this.active] = 'active';
      _this.slideclass[_this.previndex] = '';
      _this.update();
      return _this.animating = false;
    }, 700);
  };
})(this);

this.goto = (function(_this) {
  return function(e) {
    if (_this.animating) {
      return;
    }
    _this.previndex = _this.active;
    _this.active = e.item.i;
    if (_this.active < _this.previndex) {
      _this.slideprev();
    } else {
      _this.slidenext();
    }
    return _this.update();
  };
})(this);
});
    
  