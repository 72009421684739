<script>
  import {onMount,tick} from "svelte"
  import {Button} from "duo-kit"

  export let langs = ''
  export let showflags = false

  let node,tag,loading,loadedTranslate,combo,ev,currentlang="en",cookie,crumb,optionslist;

  onMount(()=>{
    let editmode = getParams('cms')
    if(editmode){ tag = node.getRootNode().host } else { tag = node }
    loadTranslate()
    let observer = new MutationObserver((mutation)=>{
      if(document.querySelector('body > .skiptranslate') && window.getComputedStyle(document.querySelector('body > .skiptranslate')).display != "none"){
        document.querySelector('.navbar-default').classList.add('translating')
      }else{
        document.querySelector('.navbar-default').classList.remove('translating')
      }
    })
    observer.observe(document,{attributes:false,childList:true,characterData:true,subtree:true})
  })

  function googleTranslateInit() {
    const checkIfGoogleLoaded = setInterval(() => {
      if (window.google != null && window.google.translate != null && window.google.translate.TranslateElement != null) {
        clearInterval(checkIfGoogleLoaded)
        googleTranslateElementInit()
      }
    }, 500)
  }

  function googleTranslateElementInit() {
    if(!document.querySelector('.goog-te-combo')){
      new window.google.translate.TranslateElement({pageLanguage: 'en', includedLanguages:langs, layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL}, 'google_translate_element');
    }
    // setCurrentFlag()
  }

  function createListFromSelect(){
    console.log("creating list")
    optionslist = document.querySelectorAll('.goog-te-combo option')
    console.log(optionslist)
    // let langmenu = document.createElement('UL')
    // langmenu.className = 'dropdown-menu'
    // optionslist.forEach((option) => {
    //   let item = document.createElement('LI')
    //   item.className = 'gtranslate-menuitem'
    //   item.id = 'gtranslate-'+option.value
    //   let itemlink = document.createElement('A')
    //   itemlink.innerText = option.innerText
    //   item.appendChild(itemlink)
    //   langmenu.appendChild(item)
    // })
    // let parent = document.querySelector('.mainmenu > ul > li:last-child')
    // parent.appendChild(langmenu)
  }

  function setCurrentFlag(){
    const checkLang = setInterval(()=>{
      // console.log(window.google.translate.TranslateElement())
      // if(window.google.translate && window.google.translate.TranslateElement().A){
      if(window.google.translate && document.querySelectorAll('.goog-te-combo option').length > 0){
        clearInterval(checkLang)
        cookie = document.cookie.match(new RegExp('(^| )googtrans=([^;]+)'))
        crumb = (cookie && cookie[2]) ? cookie[2].substring(cookie[2].lastIndexOf('/')+1) : "en"
        currentlang = crumb || "en"
        console.log(document.querySelector('.goog-te-combo'))
        // createListFromSelect()
      }
    },100)
  }

  function loadTranslate() {
    loading = true
    const domElement = document.createElement('script')
    domElement.setAttribute('src', '//translate.google.com/translate_a/element.js')
    domElement.onload = () => {
        loadedTranslate = true
    }
    document.body.appendChild(domElement)
    googleTranslateInit()
  }

  function translate(lang){
    if(lang!="en"){
      if(lang == currentlang){
        return
      }
      combo = tag.querySelector('.goog-te-combo')
      if(combo){
        combo.value = lang
        if(typeof(Event)=="function"){
          ev = new Event('change')
        }else{
          ev = new Event('change',{"bubble":true,"cancelable":false})
        }
        combo.dispatchEvent(ev)
        currentlang = lang
      }
    }else{
      currentlang = "en"
      const iframe = document.querySelector('iframe.goog-te-banner-frame')
      const doc = iframe.contentDocument ? iframe.contentDocument : iframe.contenWindow.document
      const doclink = doc.querySelector('.goog-close-link')
      doclink.click()
    }
  }

  function selectLanguage(lang){
    console.log(lang)
    let selector = document.querySelector('.goog-te-combo')
    selector.value = lang
    if(typeof(Event)=="function"){
      ev = new Event('change')
    }else{
      ev = new Event('change',{"bubble":true,"cancelable":false})
    }
    selector.dispatchEvent(ev)
    // currentlang = lang
  }

  const getParams = (param)=>{
    param = param.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + param + '=([^&#]*)')
    var results = regex.exec(location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }

</script>

<div class="gtranslate" bind:this={node}>
  <!-- <div class="translate-buttons">
    {#if showflags}
      {#each langs.split(',') as lang}
        <img class="flag {currentlang==lang ? 'active' : ''}" src="/images/assets/flags/flag-{lang}.svg" alt="{lang} flag" width="48" height="25" on:click={()=> translate(lang)}>
      {/each}
    {/if}
  </div> -->
  <!-- <slot /> -->
  <div id="google_translate_element"></div>
</div>
<!-- <span class="translate-button" part="translatebutton" on:click={()=> openSelect()}>Select Language</span> -->

<style>
  .translate-buttons{
    position:absolute;
    top:10px;
    right:10px;
    overflow:hidden;
    min-width:85px;
    text-align:center;
    z-index:4;
  }
  .translate-buttons .flag{
    display:inline-block;
    margin:0 3px;
    transition:opacity ease 0.3s;
    border:1px solid rgba(255,255,255,0.3);
    cursor:pointer;
    opacity:0.75;
    transition:all ease 0.3s;
  }
  .translate-buttons .flag.active{
    border-color:rgba(255,255,255,0.7);
    opacity:1;
  }
  @media(max-width:900px){
    .translate-buttons{
      left:10px;
      right:auto;
    }
  }

  :global(body){
    top:0!important;
  }
</style>