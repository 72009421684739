
    var riot = require('riot')
    
riot.tag2('dropdown', '<yield></yield>', 'dropdown { display: block; position: relative; }', '', function(opts) {
this.show = false;

this.on('mount', function() {
  this.root.addEventListener('click', this.toggle);
  if (this.root.querySelectorAll(".active").length > 0 || this.root.className.match(/active/)) {
    this.root.className += " open";
    return this.show = true;
  }
});

this.on('unmount', function() {
  this.root.removeEventListener('click', this.toggle);
  return document.removeEventListener('click', this.hide);
});

this.hide = (function(_this) {
  return function(e) {
    _this.show = false;
    document.removeEventListener('click', _this.hide);
    _this.root.className = _this.root.className.replace(/ open/, '');
    return _this.update();
  };
})(this);

this.toggle = (function(_this) {
  return function(e) {
    var ref, ref1;
    if ((opts.maxwidth != null) && document.body.offsetWidth > opts.maxwidth) {
      return;
    }
    if (e) {
      e.stopPropagation();
    }
    if (e && (((ref = e.target) != null ? (ref1 = ref.attributes) != null ? ref1.target : void 0 : void 0) == null)) {
      e.preventDefault();
    }
    if (e.target.className.match(/expandable/)) {
      return;
    }
    _this.show = !_this.show;
    if (_this.show) {
      document.addEventListener('click', _this.hide);
      _this.root.className += " open";
      _this.update();
    } else {
      _this.hide();
    }
    return false;
  };
})(this);
});
    
  