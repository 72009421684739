<script>
  import {Modal,Button} from "duo-kit"
  export let necessary = "", optional = ""
  let open = false,infopanel = null,analytics = false, features = []
  let show = !document.cookie.includes("cookieconsent=")
  let necessaryRows, optionalRows
  try{ necessaryRows = JSON.parse(necessary) }catch(err){}
  try{ optionalRows = JSON.parse(optional) }catch(err){}

  let allFeatures = ["youtube","vimeo","googlemaps","recapture"]

  function acceptAll(){

    document.cookie = `cookieconsent=${allFeatures.concat("analytics").join(",")};path=/;max-age=31536000`
    show = false
    window.location.reload()
  }

  function declineAll(){
    document.cookie = "cookieconsent=0;path=/;max-age=31536000"
    show = false
  }

  function showSettings(){
    open = true
  }

  function toggleFeature(e){
    let feature = e.target.name 
    if(features.includes(feature)){
      features = features.filter(f => f!=feature)
    }else{
      features = features.concat(feature)
    }
  }

  function toggleAllFeature(){
    features = features.length > 3 ? [] : allFeatures
  }

  function saveSettings(){
    document.cookie = `cookieconsent=${features.join(",")};path=/;max-age=31536000`
    show = false
    window.location.reload()
  }

  function toggleInfo(e,idx){
    if(!e.target.closest('.infotitle') || e.target.closest('.infotools')) return
    if(idx===infopanel) return infopanel = null 
    infopanel = idx
  }

</script>

{#if show}
  <div class="cookie-banner">
    <div class="message">
      <slot />
    </div>
    <div class="options">
      <button on:click={acceptAll}>Accept All</button> 
      <button on:click={showSettings}>Options</button> 
      <button on:click={declineAll}>Decline All</button> 
    </div>
  </div>
{/if}

<Modal bind:open={open} heading="Privacy Options" style="width:700px;z-index:5000001">
  <div class="modal-body">
    <slot name="overview">
      <p>
        This website uses cookies to improve your experience while you navigate through the website. 
        Out of these, the cookies that are categorized as necessary are stored on your browser as they are essential for the working of basic functionalities of the website. 
      </p><p>
        We also use third-party services, which embed cookies that help us analyze and understand how you use this website. 
        These cookies will be stored in your browser only with your consent. 
        You also have the option to opt-out of these cookies. 
        But opting out of some of these cookies may affect your browsing experience. 
      </p>
    </slot>
    
    <div class="infopanel" class:active={infopanel==0} on:click={(e)=> toggleInfo(e,0)}>
      <div class="infotitle">
        <h4>Necessary</h4>
        <div class="infotools">Always Enabled</div>
      </div>
      <div class="panelinner">
        <slot name="necessary">
          <p>
            Necessary cookies are absolutely essential for the website to function properly. 
            These cookies ensure basic functionalities and security features of the website, anonymously.
          </p>
        </slot>
        <table class="table">
          <thead>
            <tr><th>Cookie</th>
                <th style="width:110px;">Duration</th>
                <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>duocms.session</td><td>1 day</td><td>Used by the site to store preferences and site specific functionality</td>
            </tr>
            <tr>
              <td>duocms.session.sig</td><td>1 day</td><td>Used for security purposes</td>
            </tr>
            <tr>
              <td>cookieconsent</td><td>1 year</td><td>Used to store your cookie preferences</td>
            </tr>
            {#if Array.isArray(necessaryRows)}
              {#each necessaryRows as row}
                <tr>
                  <td>{row[0]}</td><td>{row[1]}</td><td>{row[2]}</td>
                </tr>
              {/each}

            {/if}
          </tbody>
        </table>
        <slot name="necessary" />
      </div>
    </div>

    <div class="infopanel" class:active={infopanel==2} on:click={(e)=> toggleInfo(e,2)}>
      <div class="infotitle">
        <h4>Features</h4>
        <div class="infotools">
          <label for="accept2">Accept</label>
          <input id="accept2" on:click={toggleAllFeature} checked={features.length > 3} type="checkbox" />
        </div>
      </div>

     
      <div class="panelinner">
        <slot name="optional">
          <p>This site embeds code into some web pages from third party providers including Google, Youtube and Vimeo to provide 
           various features including Maps, Video and to reduce spam form filling.
          </p>
          <p>These third party providers may use these embeds to track your usage and in some cases also save cookies. </p>
        </slot>

        <table class="table">
          <thead>
            <tr><th>Provider</th><th>Description</th><th style="width:70px;">Enable</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>YouTube</td>
              <td>Allows you to view youtube videos embedded in this website, without visiting youtube.com directly</td>
              <td><div class="checkbox"><input type="checkbox" name="youtube" on:click={toggleFeature} checked={features.includes("youtube")} /></div></td>
            </tr>
            <tr>
              <td>Vimeo</td>
              <td>Allows you to view Vimeo videos embedded in this website, without visiting vimeo.com directly</td>
              <td><div class="checkbox"><input type="checkbox" name="vimeo" on:click={toggleFeature} checked={features.includes("vimeo")} /></div></td>
            </tr>
            <tr>
              <td>Google Maps</td>
              <td>Allows you to view maps embedded in this website, without visiting maps.google.com directly</td>
              <td><div class="checkbox"><input type="checkbox" name="googlemaps" on:click={toggleFeature} checked={features.includes("googlemaps")} /></div></td>
            </tr>
            <tr>
              <td>Google Recapture</td>
              <td>Allows us to reduce spam being sent through our feedback forms.</td>
              <td><div class="checkbox"><input type="checkbox" name="recapture" on:click={toggleFeature} checked={features.includes("recapture")} /></div></td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

    <div class="infopanel" class:active={infopanel==1} on:click={(e)=> toggleInfo(e,1)}>
      <div class="infotitle">
        <h4>Analytics</h4>
        <div class="infotools">
          <label for="accept1">Accept</label>
          <input id="accept1" name="analytics" checked={features.includes("analytics")} type="checkbox" />
        </div>
      </div>

     
      <div class="panelinner">
        <slot name="optional">
          <p>Analytics cookies are used for various reasons including to understand how visitors interact with this website. 
            These cookies help provide information on metrics the number of visitors, 
            bounce rate, traffic source, etc.
          </p>
        </slot>

        <table class="table">
          <thead>
            <tr><th>Cookie</th><th style="width:110px;">Duration</th><th>Description</th></tr>
          </thead>
          <tbody>
            <tr><td>_ga</td><td>2 years</td><td>The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.</td></tr>
            <tr><td>_gat_gtag</td><td>1 minute</td><td>Set by Google to distinguish users.</td></tr>
            <tr><td>_gid</td><td>1 day</td><td>Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.</td></tr>
          </tbody>
        </table>
      </div>
    </div>



    {#if Array.isArray(optionalRows)}
    {#each optionalRows as row}
      <tr>
        <td>{row[0]}</td><td>{row[1]}</td><td>{row[2]}</td>
      </tr>
    {/each}
  {/if}
  </div>
  <div slot="toolbar" style="text-align:right">
    <Button mode="success" on:click={saveSettings}>Save & Accept</Button>
  </div>
</Modal>

<style>
.cookie-banner{
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  background-color:rgba(0,0,0,0.8);
  padding:25px;
  color:white;
  display:flex;
  flex-wrap:wrap;
  font-size:14px;
  gap:15px;
  font-weight:400;
  z-index:1059;
}
.message{
  flex:1 1 400px;
}

.message::slotted{
  color:white;
  font-weight:400;
}
.options{
  display:flex;
  gap:5px;
  align-items: center;
  justify-content: center
}
.modal-body{
  padding:20px;
}

button{
  background:black;
  border:1px solid white;
  padding:0.5em 1em;
  display:flex;
  color:white;
}

.infopanel{
  max-height:50px;
  overflow:hidden;
  font-size:14px;
  transition:max-height 0.3s;
}

.infopanel.active{
  max-height:800px;
}

.infopanel .infotitle{
  background:#eee;
  padding:5px 30px;
  border-radius:5px;
  position: relative;
  cursor:pointer;
}

.infopanel .infotitle h4{
  font-size:16px;
  padding:0;
  margin:0.5rem 0 0.5rem 0;
}

.infopanel .infotitle:before{
  content:"";
  position: absolute;
  top: calc(50% - 10px);
  left: 5px;
  height:20px;
  width:20px;
  background-image:url('data:image/svg+xml;utf8,<svg width="100%" height="100%" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path style="fill:%23444" d="m66.156 50l-2.2188-2.5938-24-28-6.0938 5.1875 21.781 25.406-21.781 25.406 6.0938 5.1875 24-28z"/></svg>');
  transition:transform 0.3s;
}

.infopanel.active .infotitle:before{
  transform: rotate(90deg);
}

.infopanel h4:is(span,label){
  font-size:12px;
  text-align:right;
}

.infotools{
  position: absolute;
  right:20px;
  top:0px;
  display:flex;
  gap:10px;
  align-items: center;
  height:45px;

}
.infotools > *{
  margin:0;
}

.panelinner{
  padding:10px;
}

thead{
  background:#777;
  color:white;
}

.checkbox{
  display:flex;
}
.checkbox input[type=checkbox]{
  position: relative;
    width: 44px;
    height: 24px;
    margin: 0;
    background: #d0d5d2;
    -webkit-appearance: none;
    border-radius: 50px;
    cursor: pointer;
    outline: 0;
    border: none;
    top: 0;
}
.checkbox input[type=checkbox]:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin: 0;
}

.checkbox input[type=checkbox]:checked{
  background:var(--s-success,#28a745);
}

.checkbox input[type=checkbox]:checked:before{
  transform: translateX(20px);
}

</style>