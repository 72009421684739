
    var riot = require('riot')
    require('riot-kit/lib/datepicker'); require('riot-kit/lib/modal');require('riot-kit/lib/form');require('riot-kit/lib/formdate');require('riot-kit/lib/confirm');require('riot-kit/lib/dateformat')
riot.tag2('ical', '<div class="{opts.fullpage ? \'row\' : \'\'}"> <div if="{loading}" class="loader"> <div class="fa fa-spinner fa-pulse fa-5x"></div> </div> <div if="{!loading}"> <div class="{opts.fullpage ? \'col-xs-12 col-md-4\' : \'\'}"> <datepicker if="{!opts.hidecalendar}" date="{new Date()}" onupdate="{setDate}" datestyle="{handleDateStyle}"></datepicker> </div> <div class="{opts.fullpage ? \'col-xs-12 col-md-8\' : \'\'}"> <h4 if="{!opts.hidetitle}" class="list-title">{listTitle || \'Upcoming Events\'}</h4> <eventlist events="{daysEvents || events}" viewevent="{handleViewEvent}" limit="{showUpcoming ? limit : 0}" current="{currentdate}" if="{!opts.wideview}"></eventlist> <eventlist-wide events="{daysEvents || events}" viewevent="{handleViewEvent}" limit="{showUpcoming ? limit : 0}" current="{currentdate}" if="{opts.wideview}"></eventlist-wide> </div> </div> </div> <modal name="eventmodal" title="Event Details" onclose="{true}"> <eventdetails-ical eventdata="{parent.eventdata}" ondelete="{parent.handleDelete}" onmodechange="{parent.handleChangeMode}"></eventdetails-ical> </modal>', 'ical { display: block; } ical .loader { padding: 30px 0; text-align: center; } .datepicker .subtle { color: #ccc; } .datepicker th, .datepicker td { text-align: center; } .datepicker .event { font-weight: bold; }', '', function(opts) {
var Calendar, Formatter, ICAL, eventsApi, resource;

ICAL = require('ical.js');

Calendar = require('riot-kit/utils/calendar.coffee');

Formatter = require('riot-kit/utils/dateformat.coffee');

resource = require('riot-kit/utils/apiResource.coffee');

eventsApi = new resource(opts.src || '/duocms/api/events', true);

this.loading = true;

this.events = [];

this.limit = 5;

this.showUpcoming = true;

this.datemode = "upcoming";

this.on('mount', function() {
  return eventsApi.get((function(_this) {
    return function(data, err) {
      if (err != null) {
        console.log('err', err);
      }
      data.forEach(function(icalData) {
        var comp, jCalData, vevents;
        jCalData = ICAL.parse(icalData.cal);
        comp = new ICAL.Component(jCalData);
        vevents = comp.getAllSubcomponents('vevent');
        return vevents.forEach(function(vevent) {
          var event;
          event = new ICAL.Event(vevent);
          return _this.events.push({
            id: event.uid,
            name: event.summary,
            cal: icalData.name,
            description: event.description,
            location: event.location,
            publish: true,
            link: null,
            event_times: [
              {
                id: event.uid,
                start: new Date(event.startDate),
                end: new Date(event.endDate),
                repeat: null
              }
            ]
          });
        });
      });
      _this.createActiveDates();
      _this.loading = false;
      _this.loadUpcomingEvents();
      return _this.update();
    };
  })(this));
});

this.setDate = (function(_this) {
  return function(date, changetypes) {
    if (changetypes.indexOf('month') > -1 || changetypes.indexOf('year') > -1) {
      _this.currDate = new Date(date);
      return _this.loadMonthsEvents();
    } else if (changetypes.indexOf('date') > -1) {
      _this.currDate = new Date(date);
      return _this.loadDaysEvents();
    }
  };
})(this);

this.handleDateStyle = (function(_this) {
  return function(date) {
    if (_this.activeDates.indexOf(date.getTime()) > -1) {
      return 'event';
    }
    return '';
  };
})(this);

this.createActiveDates = (function(_this) {
  return function() {
    var calendar;
    _this.activeDates = [];
    calendar = new Calendar();
    _this.events.forEach(function(event, index) {
      _this.events[index].dates = [];
      return event.event_times.forEach(function(time) {
        var repeatDates;
        repeatDates = calendar.repeat(new Date(time.start), new Date(time.end), time.repeat);
        _this.events[index].dates = _this.events[index].dates.concat(repeatDates);
        return _this.activeDates = _this.activeDates.concat(repeatDates);
      });
    });
    return _this.activeDates = _this.activeDates.map(function(date) {
      return date.setHours(0, 0, 0, 0);
    });
  };
})(this);

this.handleChangeMode = (function(_this) {
  return function(mode) {
    _this.tags.eventmodal.hide();
    _this.update();
    return setTimeout(function() {
      _this.mode = mode;
      _this.update();
      _this.tags.eventmodal.show();
      return _this.update();
    }, 250);
  };
})(this);

this.handleViewEvent = (function(_this) {
  return function(event) {
    event.event_times.forEach(function(time) {
      time.starttime = new Formatter(time.start).format('HH:mm');
      return time.endtime = new Formatter(time.end).format('HH:mm');
    });
    _this.eventdata = event;
    _this.update();
    return _this.tags.eventmodal.show();
  };
})(this);

this.loadUpcomingEvents = function() {
  var end, endDate, endDays, endMonth, endYear, start, startDate, startDay, startMonth, startYear;
  this.datemode = "upcoming";
  this.showUpcoming = true;
  this.listTitle = "Upcoming Events";
  this.currentdate = null;
  startYear = new Date().getFullYear();
  startMonth = new Date().getMonth();
  startDay = 1;
  endYear = new Date().getFullYear();
  endMonth = new Date().getMonth() + 1;
  endDays = new Date(endYear, endMonth + 1, 0).getDate();
  startDate = new Date(startYear, startMonth, startDay);
  endDate = new Date(endYear, endMonth, endDays, 23, 59, 59);
  start = new Formatter(startDate).format('yyyy-M-d');
  end = new Formatter(endDate).format('yyyy-M-d HH:mm:ss');
  this.limit = opts.limit || 5;
  this.daysEvents = null;
  this.createActiveDates();
  return this.update();
};

this.loadMonthsEvents = function() {
  var daysInMonth, end, endDate, month, start, startDate, year;
  this.datemode = "monthly";
  this.showUpcoming = false;
  this.currentdate = new Date(this.currDate).setDate(1);
  this.listTitle = new Formatter(this.currDate).format('MMMM') + "'s Events";
  year = this.currDate.getFullYear();
  month = this.currDate.getMonth();
  daysInMonth = new Date(year, month + 1, 0).getDate();
  startDate = new Date(year, month, 1);
  endDate = new Date(year, month, daysInMonth, 23, 59, 59);
  start = new Formatter(startDate).format('yyyy-M-d');
  end = new Formatter(endDate).format('yyyy-M-d HH:mm:ss');
  this.daysEvents = null;
  this.createActiveDates();
  return this.update();
};

this.loadDaysEvents = function() {
  var calendar, ref;
  this.datemode = "daily";
  this.showUpcoming = false;
  this.currentdate = this.currDate;
  this.listTitle = "Events for " + new Formatter(this.currDate).format('d MMMM');
  this.daysEvents = [];
  calendar = new Calendar(this.currDate);
  if ((ref = this.events) != null) {
    ref.forEach((function(_this) {
      return function(evt) {
        var d;
        d = _this.findClosest(evt.dates, _this.currDate);
        if (calendar.isToday(d)) {
          return _this.daysEvents.push(evt);
        }
      };
    })(this));
  }
  return this.update();
};

this.findClosest = (function(_this) {
  return function(dateArray, currentDate) {
    var date, i, len;
    if (!dateArray) {
      return null;
    }
    for (i = 0, len = dateArray.length; i < len; i++) {
      date = dateArray[i];
      if (date >= currentDate) {
        return date;
      }
    }
  };
})(this);
});
riot.tag2('eventdetails-ical', '<h2>{event.name}</h2> <p class="pre">{event.description}</p> <div if="{event.location}"> <hr> <h4>Location</h4> <p class="pre">{event.location}</p> </div> <p if="{event.link}"><a href="{event.link}">Click here for more information</a></p> <div if="{event.event_times.length}"> <hr> <h4>Event Times</h4> <table class="table table-stiped"> <thead> <tr> <th>Start Date</th> <th>End Date</th> <th>Start Time</th> <th>End Time</th> <th>Repeats</th> </tr> </thead> <tbody> <tr each="{ev in event.event_times}"> <td riot-tag="dateformat" date="{ev.start_date}" format="d MMMM yyyy"></td> <td riot-tag="dateformat" date="{ev.end_date}" format="d MMMM yyyy" if="{repeat &amp;&amp; repeat!=\'none\'}"></td> <td if="{!repeat || repeat==\'none\'}"></td> <td riot-tag="dateformat" date="{ev.start_date}" format="HH:mm"></td> <td riot-tag="dateformat" date="{ev.end_date}" format="HH:mm"></td> <td>{repeat}</td> </tr> </tbody> </table> <button if="{window.Duocms}" onclick="{editevent}" class="btn btn-success">Edit Event</button> <button if="{window.Duocms}" riot-tag="confirm" onconfirm="{deleteevent}" class="btn btn-danger">Delete Event</button> </div>', 'eventdetails .pre{ white-space: pre-wrap }', '', function(opts) {
this.on('update', function() {
  this.event = opts.eventdata;
  if (this.event && this.event.event_times) {
    return this.event.event_times.map((function(_this) {
      return function(ev) {
        ev.start_date = new Date(ev.start);
        return ev.end_date = new Date(ev.end);
      };
    })(this));
  }
});

this.editevent = (function(_this) {
  return function() {
    return opts.onmodechange('edit');
  };
})(this);

this.deleteevent = (function(_this) {
  return function() {
    return opts.ondelete(_this.event);
  };
})(this);
});











    
  