<script>
  import {onMount} from 'svelte';
  export let deadline,days,hours,minutes,seconds,timer,passed;

  onMount(async () =>{
    if(!deadline){deadline = setDeadline();}
    // initializeCount(deadline);
    timer = setInterval(() => {
      initializeCount(deadline);
    },1000);
  });

  function setDeadline(){
    let date = new Date();
    let newDate = new Date(date.setMonth(date.getMonth()+1));
    return newDate;
  }

  function initializeCount(deadline){
    let t = Date.parse(deadline) - Date.parse(new Date);
    if(t <= 0){
      if(t==0){window.location.reload();}
      return;
    }
    days = Math.floor(t / (1000 * 60 * 60 * 24));
    seconds = ('0' + Math.floor(t / 1000 % 60)).slice(-2);
    minutes = ('0' + Math.floor(t / 1000 / 60 % 60)).slice(-2);
    hours = ('0' + Math.floor(t / (1000 * 60 * 60) % 24)).slice(-2);
  }

</script>

<div class="duo-countdown">
  {#if seconds != undefined}
    {#if days > 0 || hours > 0 || minutes > 0}
      <span class="duo-countdown-metric">{days} <small>day{#if days != 1}s{/if}</small></span>
      <span class="duo-countdown-metric">{hours} <small>hour{#if hours != 1}s{/if}</small></span>
      <span class="duo-countdown-metric">{minutes} <small>minute{#if minutes != 1}s{/if}</small></span>
    {/if}
    {#if days == 0 && hours == 0 && minutes == 0}
      <span class="duo-countdown-metric">{seconds} <small>second{#if seconds != 1}s{/if}</small></span>
    {/if}
  {/if}
</div>

<style>
  .duo-countdown-metric{
    font-size:50px;
  }
</style>