
    var riot = require('riot')
    
riot.tag2('star_rating', '<div class="row nopadding"> <form if="{!successful &amp;&amp; !error}" onsubmit="{addComment}" class="form star_rating"> <input id="demo-1" type="radio" name="stars" value="1" onchange="{checkstars.bind(null,1)}"> <label for="demo-1">1 star</label> <input id="demo-2" type="radio" name="stars" value="2" onchange="{checkstars.bind(null,2)}"> <label for="demo-2">2 stars</label> <input id="demo-3" type="radio" name="stars" value="3" onchange="{checkstars.bind(null,3)}"> <label for="demo-3">3 stars</label> <input id="demo-4" type="radio" name="stars" value="4" onchange="{checkstars.bind(null,4)}"> <label for="demo-4">4 stars</label> <input id="demo-5" type="radio" name="stars" value="5" onchange="{checkstars.bind(null,5)}"> <label for="demo-5">5 stars</label> <div class="stars pull-left"> <label for="demo-1" aria-label="1 star" title="1 star"></label> <label for="demo-2" aria-label="2 stars" title="2 stars"></label> <label for="demo-3" aria-label="3 stars" title="3 stars"></label> <label for="demo-4" aria-label="4 stars" title="4 stars"></label> <label for="demo-5" aria-label="5 stars" title="5 stars"></label> </div> <button type="submit" if="{!disabled}" class="btn btn-primary pull-right">Rate</button> </form> </div> <div if="{successful}" class="alert alert-success"> <p>Thank you, your rating has been recorded.</p> </div> <div if="{error}" class="alert alert-success"> <p>Sorry, there was a problem sending your rating. Please try again later.</p> </div>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/stars', true);

this.stars = 0;

this.checkstars = (function(_this) {
  return function(val) {
    return _this.stars = val;
  };
})(this);

this.addComment = (function(_this) {
  return function() {
    var newComment;
    newComment = {
      page_id: opts.page_id,
      name: "Anonymous",
      email: "Anonymous",
      comments: "Anonymous",
      stars: _this.stars
    };
    return api.save(newComment, function(data, err) {
      if (err != null) {
        _this.error = true;
        _this.update();
        console.log('comment unsuccessful', err);
        return;
      } else {
        _this.successful = true;
        _this.reset();
      }
      return setTimeout(function() {
        _this.error = false;
        _this.successful = false;
        _this.disabled = true;
        return _this.update();
      }, 4000);
    });
  };
})(this);

this.reset = (function(_this) {
  return function() {
    return _this.update();
  };
})(this);
});
riot.tag2('star_admin', '<div if="{comments.length == 0}"> <h5>No star ratings yet!</h5> </div> <div if="{comments.length &gt; 0}"> <h5>Average rating for this page</h5><span class="staricon {avgstars&gt;=1 ? \'active\' : \'\'}"></span><span class="staricon {avgstars&gt;=2 ? \'active\' : \'\'}"></span><span class="staricon {avgstars&gt;=3 ? \'active\' : \'\'}"></span><span class="staricon {avgstars&gt;=4 ? \'active\' : \'\'}"></span><span class="staricon {avgstars==5 ? \'active\' : \'\'}"></span><span><small><em>({comments.length} total ratings)</em></small></span> </div>', '.comments .comment { border: 1px solid #eee; border-width: 1px 0 0 0; padding-bottom: 10px; } .comments .comment:last-child { border-width: 1px 0; } .comments .comment .profile { padding: 11px; margin: 15px; border: 1px solid #eee; }', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/comments', true);

this.avgstars = 0;

this.on('mount', function() {
  return this.getAllComments();
});

this.fixComments = (function(_this) {
  return function(comments) {
    return comments.replace(/\r\n|\r|\n/g, "<br />");
  };
})(this);

this.getAllComments = (function(_this) {
  return function() {
    return api.get({
      id: opts.page_id
    }, function(data) {
      _this.comments = data;
      if (_this.comments.length > 0) {
        _this.getStarRating();
      }
      return _this.update();
    });
  };
})(this);

this.getStarRating = (function(_this) {
  return function() {
    var total;
    total = 0;
    return [].map.call(_this.comments, function(comment) {
      total += comment.stars;
      _this.avgstars = Math.ceil(total / _this.comments.length);
      return _this.update();
    });
  };
})(this);
});
    
  