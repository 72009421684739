let isEditMode = window.location.search.match(/editmode=true/)
let componentCss = ""
document.querySelectorAll("link").forEach( tag => {
  let url = tag.getAttribute("href")
  if(url.startsWith("/resources/css/site")){
    componentCss = url
  }
})

import {component} from "duo-kit"
import Countdown from "./svelte/countdown.svelte"
import Gtranslate from "./svelte/gtranslate.svelte"
import CookieBanner from "./svelte/cookie-banner.svelte"
component({component:CookieBanner,tagname:"cookie-banner",href:componentCss,shadow:isEditMode})
component({component:Countdown,tagname:"duo-countdown",href:"/resources/css/site.css",shadow:isEditMode,attributes:["deadline"]})
component({component:Gtranslate,tagname:"duo-translate",href:"/resources/css/site.css",shadow:isEditMode,attributes:["langs"]})

var mo, mutationObserver, riot, vidlinks, vboxes, link;

window.riot = riot = require('riot');

require('riot-kit/lib/slideshow');
require("riot-kit/lib/img_loader");
require('riot-kit/lib/form');
require('riot-kit/lib/twitter');
require('riot-kit/lib/modal');
require('riot-kit/lib/form');
require('riot-kit/lib/ecommerce');
require('riot-kit/lib/pagination');
require('riot-kit/lib/dateformat');
require('riot-kit/lib/booking-calendar');
require('riot-kit/lib/dropdown');
require('riot-kit/lib/gmap');
require('riot-kit/lib/auth/forgot-password');
require('riot-kit/lib/gallery');
require('./tags/loginbar.tag');
require('./tags/ecommercebar.tag');
require('./tags/navigation.tag');
require('./tags/mydetails.tag');
require('./tags/mypassword.tag');
require('./tags/signup.tag');
require('./tags/tags-input.tag');
require('./tags/comments_input.tag');
require('./tags/comments_output.tag');
require('./tags/recaptcha.tag');
require('./tags/pricetag.tag');
require('./libs/slideshow.coffee');
require('./tags/eventcalendar.tag');
require('./tags/ical.tag');
require('./tags/twitter.tag');
require('./tags/searchbutton.tag');
require('./tags/videomodal.tag');
require('./tags/dropdown.tag');
require('./tags/signin.tag');
require('./tags/multimap.tag');
require('./tags/gmap.tag');
require('./tags/stars.tag');
// require('./tags/gtranslate.tag');

// let lettings = document.querySelector('[href="/lettings/lettings-login/"]')
// console.log("lettings",lettings);
// if(lettings){
//   console.log("has lettings");
//   lettings.setAttribute("riot-tag","signin-modal");
//   lettings.removeAttribute("href");
//   lettings.removeAttribute("target");
//   lettings.setAttribute("on-login-redirect","/lettings/lettings-login/");
//   lettings.setAttribute("on-logout-redirect","/");
//   console.log("lettings",lettings);
// }

riot.mount('*');

document.addEventListener('duocms:updated', function() {
  return riot.mount('slideshow,gmap');
});

vidlinks = document.querySelectorAll('.figure-videolink');

if (vidlinks) {
  [].map.call(vidlinks, (function(_this) {
    return function(vidlink) {
      var link;
      link = vidlink.querySelector('a[href]');
      if (link) {
        return vidlink.addEventListener('click', function(e) {
          e.preventDefault();
          return link.click();
        });
      }
    };
  })(this));
}

mutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

mo = new mutationObserver(function(mutations) {
  var m, mutation, results;
  m = 0;
  results = [];
  while (m < mutations.length) {
    mutation = mutations[m];
    if (mutation.type === "attributes" && mutation.target.className.match(/navshowing/)) {
      [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach(function(subnav) {
        return subnav.addEventListener('click', function(e) {
          var subsecs;
          if (subnav.getAttribute('riot-tag') === "dropdown" && subnav.className.match(/open/)) {
            e.preventDefault();
            subnav.querySelector('a,i').addEventListener('click', function(e) {
              return window.location.href = subnav.querySelector('a').href;
            });
            subsecs = subnav.querySelectorAll('.subnav a');
            if (subsecs.length > 0) {
              return [].slice.call(subsecs).map((function(_this) {
                return function(sec) {
                  return sec.addEventListener('click', function(e) {
                    return window.location.href = sec.href;
                  });
                };
              })(this));
            }
          }
        });
      });
    }
    results.push(m++);
  }
  return results;
});

mo.observe(document.querySelector('body'), {
  attributes: true
});

// vboxes = document.querySelectorAll('.block-virtualresources,.block-virtualnotes')
// if(vboxes && vboxes.length > 0){
//   [].map.call(vboxes,function(vbox){
//     vbox.addEventListener('click',function(e){
//       console.log(e);
//       link = e.target.querySelectorAll('a[href]')[0];
//       if(link.getAttribute('href')){ window.location.href = link.getAttribute('href'); }
//     });
//   });
// }