
    var riot = require('riot')
    
riot.tag2('multimap', '<gmap name="gmap" postcode="L31 6DE" title="Associated Merseyside Partnership SCITT" zoom="15" hidemarker="{true}" key="AIzaSyA6IwdPV1BqaNFJsnk6gM2CeKIhtuJIhfI" primary="#D71831" secondary="#1D2C4D" class="mymap"> <div>Associated Merseyside Partnership SCITT<br>Deyes High School<br>Deyes Lane<br>Maghull<br>L31 6DE<br><br>Tel: 0151 526 3814</div> </gmap> <gmap-markers markers="{locations}" linkedmap="gmap" icon="/images/assets/mapicon.png"></gmap-markers>', '', '', function(opts) {
this.locs = this.opts.locations || "Associated Merseyside Partnership SCITT, Deyes High School, Deyes Lane, Maghull, L31 6DE";

this.locations = [];

this.locarr = this.locs.split("\n");

this.locarr.map((function(_this) {
  return function(loc, id) {
    var regex;
    regex = /[a-z]{1,2}[0-9]{1,2} ?[0-9]{1,2}[a-z]{1,2}/gi;
    return _this.locations.push({
      "postcode": loc.match(regex).toString(),
      name: loc.replace(/,/g, "<br>")
    });
  };
})(this));
});
riot.tag2('multimap-advanced', '<div if="{!opts.editmode}"> <gmap name="gmap" postcode="L31 6DE" title="Associated Merseyside Partnership SCITT" zoom="15" hidemarker="{true}" key="AIzaSyA6IwdPV1BqaNFJsnk6gM2CeKIhtuJIhfI" primary="#D71831" secondary="#1D2C4D" class="mymap"> <div>Associated Merseyside Partnership SCITT<br>Deyes High School<br>Deyes Lane<br>Maghull<br>L31 6DE<br><br>Tel: 0151 526 3814</div> </gmap> <gmap-markers markers="{locations}" linkedmap="gmap" icon="/images/assets/mapicon.png"></gmap-markers> </div> <div if="{opts.editmode}"> <div class="editmode"> <h4>Locations</h4> <div each="{loc,i in locs}" class="locations"> <div class="location location-titles"> <div><strong>Name</strong></div> <div><strong>Address</strong></div> <div><strong>Postcode</strong></div> <div><strong>URL</strong></div> </div> <div class="location"> <div class="location-name"> <input type="text" name="pages.multilocs[{i}].name" value="{loc.name}" class="form-control duoEdittext"> </div> <div class="location-address"> <input type="text" name="pages.multilocs[{i}].address" value="{loc.address}" class="form-control duoEdittext"> </div> <div class="location-postcode"> <input type="text" name="pages.multilocs[{i}].postcode" value="{loc.postcode}" class="form-control duoEdittext"> </div> <div class="location-url"> <input type="text" name="pages.multilocs[{i}].url" value="{loc.url}" class="form-control duoEdittext"> </div> </div> </div> <div class="row"> <div class="col-xs-12"><br> <button onclick="{addRow}" class="btn-btn-primary pull-right">Add Row</button> </div> </div> </div> </div>', '.locations .location { display: grid; grid-template-columns: 20% 30% 15% 26%; grid-gap: 3%; }', '', function(opts) {
this.on('mount', (function(_this) {
  return function() {
    console.log("advanced multi map", _this.opts.p, _this.opts.locs);
    _this.locs = _this.opts.locs || [
      {
        name: "Associated Merseyside Partnership SCITT",
        address: "Deyes High School, Deyes Lane, Maghull",
        postcode: "L31 6DE",
        url: ""
      }
    ];
    _this.locations = [];
    _this.locs.map(function(loc, id) {
      return _this.locations.push({
        "postcode": loc.postcode
      });
    });
    return _this.update();
  };
})(this));

this.addRow = (function(_this) {
  return function() {
    _this.locs.push({
      name: "",
      address: "",
      postcode: "",
      url: ""
    });
    return _this.update();
  };
})(this);
});
    
  